import { MinusCircleFilled, PlusCircleFilled } from '@ant-design/icons';
import { Form, Input, Modal, Table } from 'antd';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import styles from "./activationCodes.module.css";
import { cloneDeep, isEqual } from "lodash";
import { removeNonDigits } from '../../../../helpers/helperFunctions';
import { useDispatch, useSelector } from 'react-redux';
import { codeActions } from '../../../../api/_actions/code.actions';
import FullScreenSpinner from '../../../../components/FullscreenSpinner/fullscreenSpinner';
import Share from '../../../../components/Share/share';
import rules from "../../../../helpers/validation/keypadValidation";
import { getUser } from '../../../../helpers/sessionHelper';
import { callFunctionActions } from '../../../../api/_actions/callFunction.actions';
import { LongPressDetectEvents, useLongPress } from "use-long-press";
import CollapseContent from '../../../../components/CollapseContent/collapseContent';
import { toggleBlink } from '../../../../helpers/navigationHelper';
import { unstable_useBlocker as useBlocker, useLocation } from 'react-router-dom';

function ActivationCodes({ sortBy, searchString, resetSearching, device, setDevice }) {
    const {
        lockerConfigIsOpen
    } = useLocation().state || {}

    useEffect(() => {
      setActivationCodes(cloneDeep(device.keypadUserCodes))
    }, [device.keypadId])
    

    const [activationCodes, setActivationCodes] = useState(cloneDeep(device.keypadUserCodes));
    const [foundActivationCodes, setFoundActivationCodes] = useState(cloneDeep(activationCodes));
    const [isUpdated, setIsUpdated] = useState(false);
    const [canEdit, setCanEdit] = useState(lockerConfigIsOpen === true)

    const codeState = useSelector(state => state.codes);
    const callFunction = useSelector(state => state.callFunction);

    const inputRef = useRef();
    const dispatch = useDispatch();
    const [form] = Form.useForm();

    const [createModal, modalContext] = Modal.useModal()

    const blocker = useBlocker(isUpdated)

    useEffect(() => {
        if (blocker.location?.state?.blinkIsOpen) {
            blocker.proceed()
        }
        else if (blocker.state === "blocked")
            unsavedChangesModal()
    }, [blocker]) // eslint-disable-line

    function unsavedChangesModal() {
        createModal.confirm({
            content: "You have unsaved changes, do you want to leave without saving?",
            icon: null,
            okText: "Yes",
            cancelText: "No",
            centered: true,
            onOk: () => {
                resetSearching()
                blocker.proceed();
            }
        })
    }

    const maxCodes = 200;

    function message(code) {
        let user = getUser();
        let newLine = "%0D%0A";

        return "" +
            `ACCESS GRANTED ${newLine}${newLine}` +
            `${user.userName} (${user.email}) has granted you access to the lock: ${device.keypadCustomName}${newLine}${newLine}` +
            `You can unlock it with the code:${newLine}` +
            `${code}`;
    }

    function addCode() {
        let formValues = form.getFieldsValue();
        let codes = cloneDeep(activationCodes);
        let duplicateIndex = codes.findIndex(userCode => userCode.name.trim().toLowerCase() === formValues.name.trim().toLowerCase());

        let newCode = { ...formValues, timestamp: Date.now() }

        if (duplicateIndex >= 0)
            codes[duplicateIndex].code = formValues.code;
        else
            codes.push(newCode);

        setActivationCodes(codes);
        form.resetFields();
        inputRef.current.focus();
    }

    function removeCode(codeToRemove) {
        setActivationCodes(prev => {
            return prev.filter(x => x.name !== codeToRemove.name)
        });
    }

    useEffect(() => {
        let codesIsUpdated = !isEqual(activationCodes, device.keypadUserCodes)

        setIsUpdated(codesIsUpdated)
    }, [activationCodes]) //eslint-disable-line

    function updateUserCodes() {
        dispatch(codeActions.updateUserCodes(device.keypadId, activationCodes))
            .then((result) => {
                if (result) {
                    openBlink();
                    setDevice({ ...device, keypadUserCodes: activationCodes })
                }
            });

    }

    function openBlink() {
        setIsUpdated(false);
        toggleBlink(true);
    }

    function duplicateNameModal() {
        createModal.confirm({
            content: <h3>Name is already in use, do you want to override?</h3>,
            className: styles.duplicateModal,
            icon: null,
            okText: "Yes",
            cancelText: "No",
            centered: true,
            onOk: addCode
        })
    }

    function maxCodeCountModal() {
        createModal.warning({
            content: <h3>You can not have more than 200 codes</h3>,
            className: styles.maxCodeModal,
            icon: null,
            centered: true,
            onOk: () => inputRef.current.focus()
        })
    }

    function submitHandler(e) {
        let duplicateName = activationCodes.find(userCode => userCode.name.trim().toLowerCase() === e.name.trim().toLowerCase());
        let limitReached = activationCodes.length >= maxCodes

        if (duplicateName) duplicateNameModal();
        else if (limitReached) maxCodeCountModal()
        else addCode();
    }

    function saveCodes() {
        let { name, code } = form?.getFieldsValue();

        if (activationCodes.length === 0) {
            createModal.warning({
                title: "You have no codes",
                style: { width: "200px" }
            })
            return;
        }

        // If form has values
        if (name || code)
            form.submit();

        else if (!isUpdated)
            openBlink()

        else
            updateUserCodes();
    }

    useEffect(() => {
        if (callFunction.function === "activationCodesSaveCodes") {
            saveCodes();
            dispatch(callFunctionActions.clear());
        }
    }, [callFunction]) // eslint-disable-line

    const columns = [
        {
            key: 'removeCode',
            className: [styles.removeCode, canEdit ? styles.show : ""],
            render: (activationCode) => <MinusCircleFilled onClick={ () => removeCode(activationCode) } />
        }, {
            dataIndex: 'code',
            key: 'code',
            className: styles.code,
            render: (text) => text
        }, {
            dataIndex: 'name',
            key: 'name',
            className: styles.name,
            ellipsis: {
                showTitle: false,
            },
            render: (text) => text
        }, {
            key: "shareCode",
            className: styles.share,
            render: ({ code }) => <Share code={ code } body={ message(code) } lockerName={ device.keypadCustomName } />
        },
    ];

    const callback = useCallback(event => {
        setCanEdit(true);
    }, [setCanEdit]);

    const bind = useLongPress(callback, {
        filterEvents: (event) => 'button' in event ? event.button === 0 : true,
        threshold: 300,
        captureEvent: true,
        cancelOnMovement: 5,
        detect: LongPressDetectEvents.BOTH
    });

    useEffect(search, [searchString, activationCodes]) // eslint-disable-line

    function search() {
        if (searchString) {
            const searchKeywords = searchString.split(" ");
            setFoundActivationCodes(activationCodes.filter(activationCode =>
                searchKeywords.every(word =>
                    activationCode.name.toLowerCase()
                        .includes(word.toLowerCase())
                )
            ));
        } else setFoundActivationCodes(activationCodes);
    }

    function sortCodes(codes) {
        if (!sortBy) return

        const { prop, order } = sortBy;

        return codes.sort((a, b) => {
            a[prop] = a[prop] === undefined ? "0" : "" + a[prop];
            b[prop] = b[prop] === undefined ? "0" : "" + b[prop];

            return order === "asc"
                ? a[prop].localeCompare(b[prop])
                : b[prop].localeCompare(a[prop])

        })
    }

    return (
        <div className={ styles.wrapper }>
            <div className={ styles.container }>
                { foundActivationCodes.length > 0
                    ? <Table rowKey="name" columns={ columns } showHeader={ false } pagination={ false } onRow={ canEdit ? null : bind } size="small"
                        dataSource={ sortCodes(foundActivationCodes) } />
                    : canEdit || activationCodes.length > 0
                        ? <div></div>
                        : <div className={ styles.noCodesFound }>
                            <h1>No Codes Found</h1>
                            <h2>Add Codes</h2>
                            <PlusCircleFilled className={ styles.addCode } onClick={ () => setCanEdit(true) } />
                        </div>
                }

                <CollapseContent show={ canEdit } style={ { position: "sticky", bottom: 0 } }>
                    <Form form={ form } className={ styles.addCodeForm } validateTrigger="onSubmit" onFinish={ submitHandler }>
                        <div className={ styles.space }></div>
                        <Form.Item rules={ rules.keypadUserCode.code } name="code" >
                            <Input ref={ inputRef } maxLength={ 8 } inputMode="numeric" className={ styles.inputCode } placeholder='Enter code'
                                onChange={ (e) => {
                                    form.setFieldsValue({ code: removeNonDigits(e.target.value) })
                                } } />
                        </Form.Item>

                        <Form.Item rules={ rules.keypadUserCode.name } name='name'>
                            <Input type="text" className={ styles.inputName } placeholder='Enter name' />
                        </Form.Item>

                        <button hidden />

                        <PlusCircleFilled className={ styles.addCode } onClick={ form.submit } />
                    </Form>
                </CollapseContent>
            </div>
            <FullScreenSpinner loading={ codeState.updateUsercodesRequest } />
            { modalContext }
        </div >
    );
}

export default ActivationCodes;