import { Collapse, DatePicker, Form, Radio, Space, QRCode } from "antd";
import styles from "./timedPinCodes.module.css";
import dayjs from "dayjs"
import { useEffect, useRef, useState } from "react";
import { MinusSquareFilled, PlusSquareFilled, SyncOutlined } from "@ant-design/icons";
import { codeActions } from "../../../../api/_actions/code.actions";
import { useDispatch, useSelector } from "react-redux";
import Share from "../../../../components/Share/share";
import { getUser } from "../../../../helpers/sessionHelper";
import cloneDeep from "lodash/cloneDeep";
import lockerImg from "../../../../assets/images/CodeLocker_Graphic_old.png";
import { keypadActions } from "../../../../api/_actions/keypad.actions";

function TimedPinCodes({ device }) {
    const loading = useSelector(state => state.codes.generateCodeRequest);
    const generatedCode = useSelector(state => state.codes.generatedCode);

    const codeListRef = useRef();

    const dispatch = useDispatch();

    const [length, setLength] = useState(1);
    const [typeOfCode, setTypeOfCode] = useState(1);
    const [date, setDate] = useState(cloneDeep(dayjs()));
    const [validFrom, setValidFrom] = useState();
    const [validTo, setValidTo] = useState();
    const [code, setCode] = useState()
    const [validFor, setValidFor] = useState(null);
    const [messageBody, setMessageBody] = useState(null);

    useEffect(() => {
        setLength(1);
        setCode(null);
        setValidFor(null);

    }, [typeOfCode]) // eslint-disable-line

    function messageHandler(type, code) {
        let user = getUser();
        let newLine = "%0D%0A";

        let message =
            `ACCESS GRANTED ${newLine}${newLine}` +
            `${user.userName} (${user.email}) has granted you access to the lock: ${device.keypadCustomName}${newLine}${newLine}` +
            `You can unlock it with the code:${newLine}` +
            `${code}${newLine}${newLine}`;

        let format;

        switch (type) {
            case 1:
                format = "dddd D MMMM YYYY";
                message += length > 0 ?
                    `The code is valid from ${validFrom.format(format)} to ${validTo.format(format)}` :
                    `The code is valid to ${validTo.format(format)}`;
                break;
            case 2:
                format = "dddd D MMMM YYYY";
                message += `The code is valid from ${cloneDeep(dayjs()).format(format)} until the end of the day (23:59:59)`;
                break;
            case 3:
                format = "dddd D MMMM YYYY HH:mm";
                message += `The code is valid from ${validFrom.format(format)} to ${validTo.format(format)}`;
                break;

            default:
                break;
        }
        return message;
    }

    useEffect(() => {
        if (code) {
            let format;
            switch (typeOfCode) {
                case 1: // Period Code
                    format = "dddd D MMMM YYYY";
                    setValidFor(
                        <table>
                            <tbody>
                                { length > 1 &&
                                    <tr>
                                        <td className={ styles.label }>Valid from: </td>
                                        <td className={ styles.date }>{ validFrom.format(format) } 00:00</td>
                                    </tr>
                                }
                                <tr>
                                    <td className={ styles.label }>{ length > 1 ? "To:" : "Valid to:" }</td>
                                    <td className={ styles.date }>{ validTo.format(format) } 23:59</td>
                                </tr>
                            </tbody>
                        </table>)

                    break;
                case 2: // One time Code
                    format = "dddd D MMMM YYYY";
                    setValidFor(
                        <table>
                            <tbody>
                                <tr>
                                    <td className={ styles.label }>Valid for: </td>
                                    <td className={ styles.date }>{ validTo.format(format) }</td>
                                </tr>
                            </tbody>
                        </table>)

                    break;
                case 3: // Hour Code
                    format = "dddd D MMMM YYYY HH:mm";
                    setValidFor(
                        <table>
                            <tbody>
                                { length > 1 &&
                                    <tr>
                                        <td className={ styles.label }>Valid from: </td>
                                        <td className={ styles.date }>{ validFrom.format(format) }</td>
                                    </tr>
                                }
                                <tr>
                                    <td className={ styles.label }>{ length > 1 ? "To:" : "Valid to:" } </td>
                                    <td className={ styles.date }>{ validTo.format(format) }</td>
                                </tr>

                            </tbody>
                        </table>)

                    break;
                default:
                    break;
            }
            setMessageBody(messageHandler(typeOfCode, code));
        }
    }, [code]) //eslint-disable-line

    useEffect(() => {
        if (generatedCode) {
            setCode(generatedCode?.code.toString().padStart(device.keypadHigSecurity ? 8 : 6, "0"));
            setValidFrom(cloneDeep(date).set("minute", 0));

            let selectedDate = cloneDeep(date)
            switch (typeOfCode) {
                case 1: // Period Code
                    setValidTo(selectedDate.add(length - 1, "d"));

                    break;
                case 2: // One time Code
                    setValidTo(cloneDeep(dayjs()));

                    break;
                case 3: // Hour Code
                    setValidTo(
                        selectedDate
                            .set('minutes', 0)
                            .subtract(1,'minute')
                            .add(length, 'h'
                            ));

                    break;
                default:
                    break;
            }
        }
    }, [generatedCode]) // eslint-disable-line

    function range(start, end) {
        const result = [];
        for (let i = start; i < end; i++) {
            result.push(i);
        }
        return result;
    }

    /*** @param {dayjs.Dayjs} current */
    function disabledDateTime(current) {
        if (current) {
            let isToday = true;

            isToday &&= current.year() === dayjs().year()
            isToday &&= current.month() === dayjs().month()
            isToday &&= current.date() === dayjs().date()

            return {
                disabledHours: () => isToday
                    ? range(0, dayjs().hour())
                    : range(0, 0)
            }
        }
    }

    function generateCode() {
        if (date) {
            let config = {
                keypadId: device.keypadId,
                typeOfCode: typeOfCode,
                year: date.year(),
                month: date.month() + 1,
                day: date.date(),
                startAt: date.hour(),
                length: length
            }

            if (typeOfCode === 1 || typeOfCode === 3) {
                config.length -= 1;
            }

            dispatch(codeActions.generateCode(config)).then((result) => {
                if (result?.success && typeOfCode === 2) {
                    dispatch(keypadActions.getAll());
                }
            });
        }
    }

    function addLength() {
        if ((typeOfCode === 1 && length < 30) ||
            (typeOfCode === 3 && length < 4)) {
            setLength(prev => prev + 1);
        }
    }

    function removeLength() {
        if (length > 1 /* || (typeOfCode === 1 && length > 0) */) {
            setLength(prev => prev - 1);
        }
    }

    function formatSwitch() {
        switch (typeOfCode) {
            case 1:
                return "DD-MM-YYYY";
            case 3:
                return "DD-MM-YYYY HH";
            default:
                break;
        }
    }

    return (
        <div className={ styles.wrapper }>
            <div className={ styles.locker }>
                <img className={ styles.lockerImage } src={ lockerImg } alt="locker">

                </img>
                <div className={ styles.lockerInfo }>
                    <div className={ styles.lockerName }>
                        <div>
                            <p>{ device.keypadCustomName }</p>
                            <p>@{ device.keypadId }</p>
                        </div>
                    </div>
                    <p className={ styles.lockerDescription }>{ device.keypadComment }</p>
                </div>
            </div>
            <Radio.Group defaultValue={ 1 } style={ { display: "block", margin: "20px" } }>
                <Space direction='vertical' size={ 8 } onChange={ (e) => setTypeOfCode(parseInt(e.target.value)) }>
                    <Radio value={ 1 }>Period Code</Radio>
                    { (device.keypadFreeLocker === 2 ||
                        device.keypadLocker === 4) &&
                        <Radio value={ 2 } >One time Code</Radio>
                    }
                    <Radio value={ 3 }>Hour Code</Radio>
                </Space>
            </Radio.Group>
            { (typeOfCode === 1 || typeOfCode === 3) &&
                <Form labelCol={ { flex: "100px" } } wrapperCol={ { flex: 1 } } colon={ false } layout="horizontal" labelAlign="left" style={ { margin: ".8rem" } } >
                    <Form.Item label="From:" >
                        <DatePicker defaultValue={ dayjs() } style={ { width: "100%" } } allowClear={ false } inputReadOnly changeOnBlur
                            disabledDate={ (current) => current.startOf("day").isBefore(dayjs().startOf("day")) }
                            disabledTime={ disabledDateTime }
                            format={ formatSwitch() }
                            showTime={ typeOfCode === 3 }
                            onChange={ setDate }
                        />

                    </Form.Item>
                    <Form.Item label="Valid for: ">
                        <Space size={ 12 } direction="horizontal" align="center">
                            <MinusSquareFilled style={ { fontSize: "1.3rem" } } onClick={ () => removeLength() } />
                            <p>{ length }</p>
                            <PlusSquareFilled style={ { fontSize: "1.3rem" } } onClick={ () => addLength() } />
                            <span>{ typeOfCode === 1 ? "days" : typeOfCode === 3 ? "hours" : "" } </span>
                        </Space>
                    </Form.Item>
                </Form>

            }
            <div className={ styles.codeGeneration }>

                <SyncOutlined className={ styles.generateCode } onClick={ () => generateCode() } spin={ loading } disabled={ typeOfCode === 2 && device.keypadOneTimeCodes?.length >= 64 } />

                { code &&
                    <>
                        <div className={ styles.code }>
                            <p className={ styles.generatedCode }>{ code }</p>
                            <Share className={ styles.share } code={ code } body={ messageBody } lockerName={ device.keypadCustomName } />
                        </div>
                        <div className={ styles.validFor }>
                            <div className={ styles.dates }>
                                { validFor }
                            </div>
                        </div>
                    </> }
            </div>
            { typeOfCode === 2 && device.keypadOneTimeCodes?.length > 0 &&
                <div ref={ codeListRef } >
                    <Collapse className={ styles.oneTimeCodesList } ghost expandIconPosition="end" onChange={ (e) => {
                        if (e[0]) {
                            setTimeout(() => {
                                let offset = codeListRef.current.offsetTop - 30;

                                let drawerBody = document.querySelector(".timed-pin-codes-drawer .ant-drawer-body ");

                                drawerBody.scrollTop = offset;
                            }, 170);
                        }
                    } }
                        items={ [{
                            label: `View generated one time codes ${device.keypadOneTimeCodes.length} / 64`,
                            children: <>
                                { device.keypadOneTimeCodes.map((oneTimeCode, index) => {
                                    let code = oneTimeCode.code;
                                    return (
                                        <div className={ styles.oneTimeCode } key={ code + index }>
                                            <p>{ code }</p>
                                            <Share className={ styles.share } code={ code } body={ messageHandler(2, code) } lockerName={ device.keypadCustomName } />
                                        </div>
                                    )
                                }) }
                            </>
                        }] } />
                </div>
            }
            { getUser().developer === true &&
                <QRCode className="qr-code" value={ `https://conlanconfigtool-payment-frontend-6i23rikpwq-lz.a.run.app/${device.keypadOwner}/${device.keypadId}/${typeOfCode === 1
                    ? "day-code" : typeOfCode === 2
                        ? "one-time-code"
                        : "hour-code"
                    }` }
                    size={ 250 }
                />
            }

        </div>
    )
}

export default TimedPinCodes;